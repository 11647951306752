<template>
  <section class="agencies">
    <!-- HEADER -->
    <section class="hero-small"
      :style="{ backgroundImage: `linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(${department.presentationImage.filename})` }"
      v-if="department">
      <div>
        <h1>
          Les agences Maisons Alysia <br /> en <span class="department-name">{{ department.name
          }}</span>
        </h1>
      </div>
    </section>
    <div class="grid-container">
      <section class="map">
        <div class="grid-x section-content map-content">
          <div class="cell medium-6 small-12 map-text">
            <p v-if="department" v-html="transformToRichText(department.agenciesDescription)"></p>
          </div>
          <div class="cell medium-6 small-12 map-illu">
            <div v-if="department">
              <img :src="department.introductionImage.filename"
                :alt="department.introductionImage.alt" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="department-agencies">
      <div class="section-content">
        <div class="agency" v-for="agency in agencies" :key="agency.agencyId">
          <div class="grid-x row">
            <div class="cell medium-3 small-12 agency-pictures">
              <span v-for="member in agenciesMembers[agency.agencyId]" :key="member.memberId">
                <img v-if="member.photo" :src="member.photo" :alt="`Photo de ${member.name}`" />
                <img v-else :src="require('@/assets/img/pages/trombinoscope/placeholder.png')"
                  :alt="`Photo de ${member.name}`" />
              </span>
            </div>
            <div class="cell medium-6 small-12 agency-text">
              <h3>{{ agency.name }}</h3>
              <p v-if="agenciesMembers[agency.agencyId] && agenciesMembers[agency.agencyId].length">
                <slot v-if="agenciesMembers[agency.agencyId][0].firstname">{{
                  agenciesMembers[agency.agencyId][0].firstname }} </slot>
                <slot v-if="agenciesMembers[agency.agencyId][0].lastname">{{ agenciesMembers[agency.agencyId][0].lastname
                }}</slot>
                <slot v-if="agenciesMembers[agency.agencyId][1]"> et </slot>
                <slot v-if="agenciesMembers[agency.agencyId][1] && agenciesMembers[agency.agencyId][1].firstname">{{
                  agenciesMembers[agency.agencyId][1].firstname }} </slot>
                <slot v-if="agenciesMembers[agency.agencyId][1] && agenciesMembers[agency.agencyId][1].lastname">{{
                  agenciesMembers[agency.agencyId][1].lastname }}</slot>
              </p>
              <p v-if="agenciesMembers[agency.agencyId]">Gérant et Chargée de Projet au sein de votre {{ agency.name }}
              </p>
              <p><strong>{{ agency.address.addressLine1 }} - {{ agency.address.city }}</strong></p>
            </div>
            <div class="cell medium-3 small-12 agency-button">
              <router-link v-if="agency.slugName" :to="`/agences/${departmentSlug}/${agency.slugName}`"><app-button>Voir
                  l'agence</app-button></router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import agencyApi from '@/services/api/agency';

import Storyblok from '@/services/storyblok';

const { VUE_APP_BRAND_ID } = process.env;

export default {
  name: 'agencies',
  data() {
    return {
      departmentSlug: null,
      department: null,
      agencies: [],
      agenciesMembers: {},
    };
  },
  watch: {
    $route() {
      this.init();
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.departmentSlug = this.$route.params.slugDepartment;
      await this.getDepartment();
      await this.getAgencies();
    },
    transformToRichText(richText) {
      const result = Storyblok.richTextResolver.render(richText);
      return result.replace(/<p><\/p>/g, '<br/>');
    },
    async getDepartment() {
      try {
        const response = await Storyblok.get(`cdn/stories/departments/${this.departmentSlug}`);
        this.department = response.data.story.content;
      } catch (err) {
        throw err;
      }
    },
    async getAgencies() {
      try {
        const response = await agencyApi.getAgencies(VUE_APP_BRAND_ID, this.department.number);
        this.agencies = response;
        await this.getMembers();
      } catch (error) {
        this.$notification.show({ text: 'Une erreur s’est produite.' });
        throw error;
      }
    },
    async getMembers() {
      try {
        this.agencies.map(async (agency) => {
          const members = await agencyApi.getAgencyMembers(agency.agencyId);
          this.$set(this.agenciesMembers, agency.agencyId, members);
        });
      } catch (error) {
        this.$notification.show({ text: 'Une erreur s’est produite.' });
        throw error;
      }
    },
  },
};

</script>

<style lang="sass">
.agencies
  overflow-x: hidden
  .hero-small
    @include hero-small
    .department-name
      color: $white
      @include heading-xl
      @media (max-width: 768px)
        @include heading-xl-mobile
  section:not(.hero-small)
    padding: 100px 0 80px 0
    display: flex
    .section-content
      display: flex
      flex: 1
      max-width: 1200px
    @media (max-width: 768px)
      padding: 75px 20px 25px 20px
  .map
    .map-text
      display: flex
      align-items: center
      padding: 0 48px
      box-sizing: border-box
      a
        color: $primary
        text-decoration: none
        font-size: 1em
        span
          text-transform: capitalize
          color: $primary
    .map-illu
      display: flex
      padding: 0 48px
      box-sizing: border-box
      &>div
        margin-left: auto
        width: 100%
        height: 400px
        img
          width: auto
          height: 100%
    @media (max-width: 768px)
      .map-content
        flex-direction: column-reverse
        .map-text
          padding: 0 20px
          h1
            text-align: center
          p
            text-align: center
          .app-button
            width: 100%
        .map-illu
          padding: 1px 20px
          box-sizing: border-box
          div
            height: fit-content
          img
            width: 100%
            height: auto
  .department-agencies
    display: flex
    flex: 1
    justify-content: center
    background: #F7F7F5
    .section-content
      display: flex
      flex: 1
      max-width: 1200px
      flex-direction: column
    .agency
      flex: 1
      background: $white
      display: flex
      align-items: center
      padding: 40px 50px
      box-shadow: 0px 2px 12px 0px rgba(196, 201, 202, 0.3)
      border-radius: 8px
      margin-bottom: 18px
      box-sizing: border-box
      .grid-x
        flex: 1
      .agency-pictures
        display: flex
        span
          display: flex
          justify-content: center
        img
          border-radius: 8px
          width: 117px
          height: 127px
          object-fit: cover
          &:first-of-type
            margin-right: 12px
      .agency-text
        h3
          margin-bottom: 24px
      .agency-button
        display: flex
        a
          margin-left: auto
          text-decoration: none
      @media (max-width: 768px)
        .agency-pictures
          display: flex
          justify-content: center
          img
            margin: 0 0.5rem
        .agency-text
          margin-top: 20px
          text-align: center
        .agency-button
          margin-top: 20px
          a
            margin: 0 auto
</style>
